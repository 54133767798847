<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                        <v-card>
                            <v-card-title style="background-color: #fff;">
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> {{ translations.views.dashboard.card_1_text }}</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('user-data')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <!-- datumska omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>{{ translations.views.dashboard.date_restriction }}</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        :label="translations.views.dashboard.label_date_from"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        :label="translations.views.dashboard.label_date_to"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- časovna omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>{{ translations.views.dashboard.time_restriction }}</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <v-menu
                                                ref="start_time_menu"
                                                v-model="start_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="start_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="start_time"
                                                    :label="translations.views.dashboard.label_time_from"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="start_time_menu"
                                                v-model="start_time"
                                                
                                                @click:minute="$refs.start_time_menu.save(start_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                                <v-menu
                                                ref="end_time_menu"
                                                v-model="end_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="end_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="end_time"
                                                    :label="translations.views.dashboard.label_time_to"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="end_time_menu"
                                                v-model="end_time"
                                                
                                                @click:minute="$refs.end_time_menu.save(end_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- naprava -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-5 pl-0"><strong>{{ translations.views.dashboard.parking }}</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_devices"
                                            :items="devices"
                                            :label="translations.views.dashboard.label_device"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <!-- tip dogodka omejitev -->
                                <!--<v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip dogodka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_event_types"
                                            :items="event_types"
                                            label="Izberi tip dogodka"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-bookmark-multiple"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"

                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->

                                <!-- id kartice -->
                                <!--<v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="card_id"
                                            label="Vpiši ID kartice"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearCardId"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                -->

                                <!-- registrska številka -->
                                <!--<v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Registrska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="registration_number"
                                            label="Vpiši registrsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearRegistrationNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                -->
                            </v-card-text>
                            <v-divider v-show="card1"></v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>{{ translations.views.dashboard.search_btn }}</v-btn><br>
                                <!--<v-btn v-if="checkApplicationPermissions(['hit-alpinea-export-events'])"  @click="exportData()" color="secondary" dark  block outlined style=""><v-icon left>mdi-export</v-icon>Izvoz&nbsp;</v-btn>-->
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>{{ translations.views.dashboard.search_btn }}</v-btn>
                                    <!--<v-btn v-if="checkApplicationPermissions(['hit-alpinea-export-events'])"  @click="exportData()" color="secondary" dark text><v-icon left>mdi-export</v-icon>Izvoz&nbsp;</v-btn>-->
                                </v-card-text>
                            </span>
                        </v-card>
                </v-flex>
            </v-layout>

            <br>
            <dashboard-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></dashboard-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            dataTableColumnsMenuId="toggle-events-list-columns"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, isMobileDevice, getUserApplicationPermissions, checkPermissions } from '@/helpers/utilities'
const DashboardDataTable = () => import('@/components/DashboardDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
import { addExportToQueue } from '@/helpers/services.js'

export default {
    components: {
        DashboardDataTable,
        DataTableToggleColumns
    },


    data: () => ({
        
        card1: true,
        card2: true,

        start_time:null,
        end_time: null,

        selected_devices: [],
        selected_event_types: [],

        restriction_start_date: null,
        restriction_end_date:null,
        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,

        start_time_menu:false,
        end_time_menu:false,

        event_types: [],
        devices: [],
        user_types: [],
        card_id: null,
        registration_number: null,

        selected_user_types: [],

        dataTableSettings: {
            id: 'dtEvents',
            title: 'Seznam parkiranj',
            sortColumn: ['event_timestamp_formatted'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/services/events',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_events',
                totalItems : {
                    event: ''
                },
                search: 'dt-events-search',
                toggleColumnsId: 'toggle-events-list-columns'
            }


        }
    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                startDate: this.restriction_start_date,
                endDate: this.restriction_end_date,
                startTime: this.start_time,
                endTime: this.end_time,
                cardId: this.card_id,
                registrationNumber: this.registration_number,
                selectedDevices: this.selected_devices.map(item => item.id),
                selectedEventTypes: this.selected_event_types.map(item => item.id),
                selectedUserTypes: this.selected_user_types.map(item => item.id),
            }
        },

        siteLanguage() {
            return this.$store.getters.siteLanguage
        },

        translations() {
            let siteLanguage = this.$store.getters.siteLanguage

            return this.$store.getters.translations[siteLanguage]
        }
    },

    methods: {
        checkApplicationPermissions(requiredPermissions) {
            return checkPermissions(requiredPermissions, getUserApplicationPermissions())
        },
        
        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        search() {
            EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
        },

        toggleCardContent(card) {
            if(card === 'user-data') {
                this.card1 = !this.card1
            }
        },

        clearCardId() {
            this.card_id = null
        },

        clearRegistrationNumber() {
            this.registration_number = null
        },

        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.event_types = response.data.eventTypes
                this.devices = response.data.devices
                this.user_types = response.data.user_types
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        exportData() {
            //window.console.log("exporting data ...");
            let parameters = {
                startDate: this.restriction_start_date,
                endDate: this.restriction_end_date,
                startTime: this.start_time,
                endTime: this.end_time,
                cardId: this.card_id,
                registrationNumber: this.registration_number,
                selectedDevices: this.selected_devices.map(item => item.id),
                selectedEventTypes: this.selected_event_types.map(item => item.id),
                selectedUserTypes: this.selected_user_types.map(item => item.id)
            }
            
            addExportToQueue('events', parameters);
        },

        slHeaders() {
            return [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "Uporabnik", value: "user_name", class: "text-xs-left", visibility: true },
                { text: "Naziv naprave", value: "device_name", class: "text-xs-left", visibility: true },
                { text: "ID kartice", value: "card_id", class: "text-xs-left", visibility: true },
                //{ text: "Št. kartice", value: "card_number", class: "text-xs-left", visibility: true },
                { text: "Registrska številka", value: "registration_number", class: "text-xs-left", visibility: true },
                //{ text: "Naziv dogodka", value: "event_name", class: "text-xs-left", visibility: true },
                { text: "Status", value: "parking_status", class: "text-xs-left", visibility: true },
                { text: "Veljavno do", value: "parking_allowed_up_to_formatted", class: "text-xs-left", visibility: true },
                { text: "Čas dogodka", value: "event_timestamp_formatted", class: "text-xs-right", align:"right", visibility: true },
                //{ text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ];
        },

        enHeaders() {
            return [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "User", value: "user_name", class: "text-xs-left", visibility: true },
                { text: "Device name", value: "device_name", class: "text-xs-left", visibility: true },
                { text: "Card ID", value: "card_id", class: "text-xs-left", visibility: true },
                //{ text: "Card number", value: "card_number", class: "text-xs-left", visibility: true },
                { text: "Registration plate", value: "registration_number", class: "text-xs-left", visibility: true },
                //{ text: "Event name", value: "event_name_en", class: "text-xs-left", visibility: true },
                { text: "Status", value: "parking_status", class: "text-xs-left", visibility: true },
                { text: "Allowed until", value: "parking_allowed_up_to_formatted", class: "text-xs-left", visibility: true },
                { text: "Event timestamp", value: "event_timestamp_formatted", class: "text-xs-right", align:"right", visibility: true },
                //{ text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ];
        }

        
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 

        if(isMobileDevice(this)) {
            this.card1 = false
        }

        this.setParametersData()
        this.restriction_start_date = getCurrentDate()
        this.restriction_start_date = "2022-01-01";
        this.restriction_end_date = getCurrentDate()
        this.start_time = "00:01"
        //this.endTime = getCurrentTime()
        this.end_time = "23:59";

        if(this.siteLanguage == "sl") {
            this.dataTableSettings.headers = this.slHeaders()
            this.dataTableSettings.title = this.translations.views.dashboard.datatable_title
        } else {
            this.dataTableSettings.headers = this.enHeaders()
            this.dataTableSettings.title = this.translations.views.dashboard.datatable_title
        }

        EventBus.$on("language-switch", (data) => {
            //window.console.log("event [language-switch]")
            //window.console.log(data)

            if(data == "sl") {
                this.dataTableSettings.headers = this.slHeaders()
                this.dataTableSettings.title = this.translations.views.dashboard.datatable_title
            } else {
                this.dataTableSettings.headers = this.enHeaders()
                this.dataTableSettings.title = this.translations.views.dashboard.datatable_title
            }
        })

        let dtHeaders = [
            { text: "ID", value: "id", class: "text-xs-left", visibility: true},
            { text: "Uporabnik", value: "user_name", class: "text-xs-left", visibility: true },
            { text: "Naziv naprave", value: "device_name", class: "text-xs-left", visibility: true },
            { text: "ID kartice", value: "card_id", class: "text-xs-left", visibility: true },
            //{ text: "Št. kartice", value: "card_number", class: "text-xs-left", visibility: true },
            //{ text: "Registrska številka", value: "registration_plate_number", class: "text-xs-left", visibility: true },
            { text: "Naziv dogodka", value: "event_name", class: "text-xs-left", visibility: true },
            { text: "Čas dogodka", value: "event_timestamp_formatted", class: "text-xs-right", align:"right", visibility: true },
            //{ text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
        ];
    },

    destroyed() {
        EventBus.$off(this.dataTableSettings.id)
        EventBus.$off(this.dataTableSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableSettings.eventBus.search)
        EventBus.$off("language-switch")
        

    }
}

</script>

<style scoped>

</style>